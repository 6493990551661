import { useState } from 'react';

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
enum SelectedFaq {
    None,
    MediaServer,
    MediaShare,
    Nas,
    SmartTv
}
function Support() {
    const [state, setState] = useState({
        selected: SelectedFaq.None
    });
    const SelectFaq = (faq: SelectedFaq) => {
        setState({ selected: faq });
    };
    let usePopup = window.innerWidth < 1024;

    return (
        <div>
            <div style={{ height: "2em" }} />
            <div className="columns" >
                {/* <img id='idBanner' src="img/about-banner.jpg" className="banner" /> */}
                <div style={{ height: "2em" }} ></div>
                <div className="column1">
                    <h4>Frequently Asked Questions</h4>

                    <p className="faqLink"><a href="javascript:" onClick={() => SelectFaq(SelectedFaq.MediaServer)}>I can't find my Windows media server.</a></p>
                    <p className="faqLink"><a href="javascript:" onClick={() => SelectFaq(SelectedFaq.MediaShare)}>I can't find my Windows media shares.</a></p>
                    <p className="faqLink"><a href="javascript:" onClick={() => SelectFaq(SelectedFaq.Nas)}>I can't find my NAS or media server.</a></p>
                    <p className="faqLink"><a href="javascript:" onClick={() => SelectFaq(SelectedFaq.SmartTv)}>I can't find my Smart TV.</a></p>


                    <h4>Contact Us</h4>
                    <div className="contacts">
                        <p>We welcome questions, feature requests, problem reports. Please read the Frequenty Asked Questions to make sure your question hasn't already been addressed.</p>
                        <p>Email us at <a id="emailLink" href="#dummy">support&#64;two&#45;play&#46;com</a></p>
                        <p>Or snail-mail us at:</p>
                        <p style={{ lineHeight: "1.2em", marginLeft: 48 }}>
                            2play Software<br />
                            1-204 Clarence St.<br />
                            Ottawa, ON K1N 5R1<br />
                            Canada.
                        </p>
                    </div>
                </div>
                <div className="column2" >
                    {state.selected !== SelectedFaq.None &&
                        (
                            <div className={usePopup ? "faqFramePopup" : "faqFrame"}>

                                {
                                    usePopup && (
                                        <div className="faqHeader">
                                            <button className="imgButton" style={{ flex: "0 0 auto" }}><img
                                                onClick={() => setState({ selected: SelectedFaq.None })} src="img/arrow_back-24px.svg" alt="back"
                                                style={{ height: 24 }} /> </button>
                                        </div>
                                    )

                                }

                                {state.selected === SelectedFaq.MediaServer &&
                                    (
                                        <div className="faqContent" style={{ overflowY: usePopup ? "auto" : undefined, flex: usePopup ? "1 1 auto" : undefined }} >
                                            <h4>I can't find my Windows media server</h4>
                                            <p>NetCaster accesses media on Windows computers via Windows Media Streaming services. To access Media Streaming services, your Android device must be
                                                connected to the same subnet as your Windows Computer, and your Windows computer must be connected to the network via a Private network connection, not a Public network connection.</p>
                                            <p>Windows Media Sharing is not enabled by default. To enable Windows Media Streaming on your computer:</p>
                                            <ol>
                                                <li>From the Start Screen or Desktop in Windows 10 press <strong><em>Win Key + I</em></strong>, and then type
                                                    <strong><em>Media Streaming Options</em></strong> in the search box.</li><li>
                                                    Click on <em><strong>Media Streaming Options,</strong></em> and follow the instructions to enable media streaming.

                                                </li></ol>
                                            <h4>Casting to Windows Computers</h4>
                                            <p>Windows Media Player can be remotely-controlled by NetCaster. Windows Media Player must be running already in order to allow remote control.</p>
                                            <p>To enable remote control of Windows Media Player on start Windows Media Player, and then select&nbsp; <strong><em>Allow Remote Control of my Player....</em></strong> from the <strong><em>Stream </em></strong>menu. You can also enable media sharing from the <strong><em>Stream </em></strong>menu as well.</p>
                                            <p style={{ textAlign: "center" }}><img src="img/mediaPlayerMenu.jpg" alt="The Media Player Stream menu." style={{ border: "1px black solid" }} /></p>

                                            <h4>Common Problems When Connecting to Windows Computers</h4>
                                            <p>You may have difficulty connecting to your Windows computer, if you have more than one router on your home network. For best results, ensure that your Android device is
                                                connected to the same subnet as your Windows computer. DLNA device discovery is performed using IGMP multicasting. In theory, routers should broadcast IGMP packets
                                                across all subnets in your home network. In practice, many routers will not forward IGMP packets from downstream connections to upstream connections. If you
                                                are unable to put all DLNA devices on the same subnet, consult the manual for your router to see if there is a way to enable forwarding of UPnP/DLNA discovery packets, or to
                                                bridge IGMP requests. Not all routers provide an option to do that.
                                            </p>

                                        </div>
                                    )
                                }
                                {state.selected === SelectedFaq.MediaShare &&
                                    (
                                        <div className="faqContent" style={{ overflowY: usePopup ? "auto" : undefined, flex: usePopup ? "1 1 auto" : undefined }} >
                                            <h4>I can't find my Windows shares</h4>

                                            <p>NetCaster can access media stored on Windows computers via Windows shares.</p>
                                            <p>Your Android device must be connected to the same network subnet as your Windows computer; and your
                                                Windows computer must be connected to the network via a Private network connection, not a Public one.</p>
                                            <p>Windows shares are enabled by default. To enable Windows shares:</p>
                                            <ol>
                                                <li>From the Start Screen or Destkop in Windows 10, press <strong><em>Win Key + I</em></strong>, and then type <strong><em>Manage advanced sharing settings</em></strong> in the
                                                    <strong><em>Find a Setting</em></strong> search box.
                                                </li><li>
                                                    Launch <strong><em>Advanced Sharing Settings</em></strong>.

                                                </li><li>Verify that the <strong><em>Turn on file and printer sharing</em></strong> setting is selected for the current network profile.</li>
                                            </ol>
                                        </div>
                                    )
                                }
                                {state.selected === SelectedFaq.Nas &&
                                    (
                                        <div className="faqContent" style={{ overflowY: usePopup ? "auto" : undefined, flex: usePopup ? "1 1 auto" : undefined }} >
                                            <h4>I can't find my NAS or media server</h4>

                                            <p>NetCaster can access content on NAS and media servers either by DLNA/UPnP, or via SMB shares.</p>
                                            <p>In both cases, the target device must be on the same network subnet as your Android device; and the appropriate service must be configured and enabled on your media server. Consult the
                                                owners' manual for your NAS device or media server for instructions on how to enable those services.</p>
                                            <p>Content shared by SMB will show up in NetCaster on the Windows Shares page. NetCaster currently supports SMB 2.0 or higher, but does not support SMB 1.0 which has been deprecated because of
                                                known significant security issues with the SMB 1.0 protocol. Consult your owners manual to find out which version of SMB your device supports.</p>
                                            <p>In some cases, you can access SMB shares that are not on your local subnet. NetCaster will be unable to automatically discover such devices; but you can manually add the device to the
                                                list of availabe devices. To manually add a device, go to the Windows Shares page, and click on the Add button found in the top-right corner of the screen. You must supply a name for the device,
                                                which must either be a name that can be resolved through DNS, or you can supply a hard-coded IP address. Note that for a hard-coded IP address to work reliably, the device must have a staticly
                                                assigned IP address. Your router's administration pages may allow you to staticly assign addresses to devices on your home network. Please consult the owners' manual for your router for
                                                instructions on how to do this.
                                            </p>
                                            <p>NetCaster will only access devices over WiFi or ethernet connections, and never access devices over Wireless data connections. This is by design.</p>
                                            <p>NetCaster identifies SMB devices both by their IP address, and by their Globally Unique Identifer, which is captured at the time you add the device.
                                                Both must match before NetCaster will connect or authenticate to an SMB share.</p>
                                        </div>
                                    )
                                }
                                {state.selected === SelectedFaq.SmartTv &&
                                    (
                                        <div className="faqContent" style={{ overflowY: usePopup ? "auto" : undefined, flex: usePopup ? "1 1 auto" : undefined }} >
                                            <h4>I can't find my smart TV</h4>

                                            <p>NetCaster can cast media to smart TVS that allow remote-control via UPnP or DLNA.</p>
                                            <p>The smart TV must be on the same network subnet as your Android device; and the appropriate service may need to be configured and enabled on your smart TV. Consult your owners' manual.</p>
                                            <p>Some smart TVs can play content served via UPnP or DLNA using the device's own remote control, but don't allow remote control via UPnP or DLNA. In this cases
                                                NetCaster cannot cast content to the TV. Consult your owners' manual to confirm that your TV allows remote control.</p>

                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                <div className="row" />
            </div>
        </div>
    );
}

export default Support;