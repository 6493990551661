import './App.css';
import { Outlet,Link, useNavigate,Location,useLocation } from "react-router-dom";

import MainPage from "./MainPage";


function GetBanner(location: string): string{
  switch(location)
  {
    case "/buy":
      return "/img/buy-banner.jpg";
    case "/about":
      return "/img/about-banner.jpg";
    case "/support":
      return "/img/support-banner.jpg";
    default:
      return "/img/wide-banner.jpg";


  }
}


function App() {
  const navigate = useNavigate();

  const navigateTo = (url: string) =>
  {
    navigate(url);  
  }
  const location: Location = useLocation();
  let path = location.pathname;

  return (
    <div className="App">
      <div className="main">
        <div className="header" >
          <div className="headerContent">

            <button onClick={(e)=> { navigateTo("");}} className="headerButton"  ><img src="img/NetCasterIcon.svg" style={{height: 10, opacity: 0.5, marginRight: 2, position:"relative", top: 1}} />2player</button>
            <button onClick={(e)=> { navigateTo("buy")}} className="headerButton">Buy now</button>
            <button onClick={(e)=> { navigateTo("about");}} className="headerButton">About 2play</button>
            <button onClick={(e)=> { navigateTo("support");}} className="headerButton">Support</button>
          </div>
        </div>
        <img id="idBanner" src={GetBanner(path)} className="banner" alt="" />
        <div className="content" id="detail">
          <Outlet />
        </div>
      </div >
    </div>
  );
}

export default App;
