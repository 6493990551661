


function MainPage() {
    return (
        <div>
            {/* <?php include 'notice.php' ?> */}

            <div style={{ height: "2em" }} ></div>
            <div className="columns">
                <div className="column1">
                    <h4>Your Media at Your Fingertips</h4>
                    <p>2player&trade; allows you to cast audio and video files between devices on your home network with your Android phone or tablet.</p>
                    <p>Cast media to Chromecast&trade; and Android™ TV devices; to connected UPnP™ or DLNA™ media players and smart TVs; or play it right on your Android phone.</p>

                    <p>Cast media from your Android phone, from UPnP or DLNA media servers; from Windows media libraries, or from Windows shares.</p>

                    <p>All of your media is at your finger-tips, ready to cast to players throughout your home. </p>

                    <p>A modern, well thought-out user-interface provides easy access, even to large media collections. </p>

                    <p>Take a quick tour of 2player on YouTube.</p>
                    <p style={{ marginLeft: 32 }}><a href="https://youtu.be/KMlDxlx0-5k" target="_blank" rel="noreferrer"><img src="img/youtube-thumbnail.jpg" alt="Youtube video" /></a></p>

                </div>
                <div className="column2">
                    <h4>Features</h4>
                    <ul>
                        <li>Cast To
                            <ul>
                                <li>Chromecast devices and Android TV boxes</li>
                                <li>UPnP and DLNA players and smart TVs</li>
                                <li>Kodi Media Player</li>
                                <li>Or play on your Android phone</li>
                            </ul>
                        </li>
                        <li>Play from
                            <ul>
                                <li>UPnP or DLNA media servers, NAS devices, routers.</li>
                                <li>Windows Media Server libraries</li>
                                <li>Network shares on Windows computers</li>
                                <li>SMB 2.0 shares on NAS devices and routers.</li>
                                <li>Media files on your Android phone</li>
                            </ul>
                        </li>
                        <li>Chromecast subtitle support
                            <ul>
                                <li>Supports transcoding of embedded video subtitle tracks when casting to Chromecast devices.</li>
                                <li>Select and play external SRT and VTT subtitle files</li>
                                <li>Download subtitles from OpenSubtitles.org</li>
                            </ul>
                        </li>
                        <li>Download content onto your phone from any network media source over WiFi
                        </li>
                        <li>Arrange Favorite Location shortcuts to put your media libraries at your fingertips
                        </li>
                    </ul>
                </div>
                <div className="row" > </div>
            </div>
            <p className="fine_print">
                Copyright © 2020-2023 2play Software.
                Chromecast is a Trademark of Google, LLC. Android is a Trademark of Google, LLC. UPnP is a trademark of the UPnP Implementers Corporation. DLNA is a Trademark of  Digital Living Network Alliance.
            </p>
        </div>

    );
}

export default MainPage;
