

function Buy() {
    return (
        <div className="onecolumn" >
            {/* <img id='idBanner' src="img/buy-banner.jpg" class="banner" /> */}
        
            <div style={{height: "2em"}} ></div>
			<h4 style={{marginLeft: 64,marginBottom: 12}}>Purchase 2player on the Google Play Store</h4>
            <p style={{marginLeft: 64}}><img src="/img/ongoogleplay.png" style={{width: 160}}/></p>
            <p style={{marginLeft: 64}}>
    			<a href="https://play.google.com/store/apps/details?id=com.twoplay.twoplayer">2player 3.0 Full Version</a>
			</p>
            <p style={{marginLeft: 84}}>Fully licensed version of 2player.</p>
            <p style={{marginLeft: 64}}>
			<a href="https://play.google.com/store/apps/details?id=com.twoplay.twoplayer">2player 3.0 Trial Version</a>
            </p>
            <p style={{marginLeft: 84}}>Fully functional version of 2player, usable for a brief trial period. You can convert to the full 
            version with an in-app purchase. </p>
        </div>
    );
}

export default Buy;