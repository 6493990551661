

function About() {
    return (
        <div className="onecolumn" >
            {/* <img id='idBanner' src="img/about-banner.jpg" className="banner" /> */}
            <div style={{ height: "2em" }} ></div>
            <div style={{marginLeft: 32, marginRight: 32}}>
                <h4>About 2play Software</h4>
                <p>2play Software has been developing quality software for Android since 2010. That's almost as long as there has been Android. Robin Davies,
                    the principal of 2play software has been developing award-winning top-of-category software for almost as long as there have been personal computers.</p>
                <p>We believe that the mark of good software is that you don't notice how good it is. Because it just works. But acheiving that level of quality requires
                    unmerciless attention to detail. We work hard so you don't have to.</p>
            </div>
            <div className="row" />
        </div>
    );
}

export default About;